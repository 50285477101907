* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* @font-face {
  font-family: Arial-Black;
  font-size: large;
  src: url(fonts/Arial/arial-black.ttf);
} */
